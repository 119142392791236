<template>
  <div class="main has-header">
    <mt-header fixed title="平台服务协议">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="content">
      <p>
        欢迎您使用“{{isXyk}}”服务平台，为了保障您的权益，我们提示您，请在签署本协议前，详细阅读并理解本协议的所有内容，特别是免除或者限制责任的条款、争议解决和法律适用条款以及开通或使用某项服务的内容，您应重点阅读并理解。如您对本协议有任何疑问应向平台客服咨询。如果您不能理解或不同意本协议的部分或全部内容，您应立即停止注册。
      </p>
      <p>
        您在我们提供的网络页面上点击“我已阅读并同意签署”、“同意”、“已阅读”等类似词语，或您与我们以其它合理方式签订本协议时，即表示您在已经充分阅读并理解内容的基础上与我们达成有效协议，同意接受本协议的约束，您不得以未仔细阅读本协议内容或平台未另行向您通知、解释为由主张本协议无效。
      </p>
      <p>
        如您在协议签署过程中有任何异议，均可向“{{isXyk}}”服务平台客服进行咨询。
      </p>
      <p>
        第一条 定义
      </p>
  
      <p>
        1.1{{isXyk}}服务平台：指包括{{isXyk}}网站、微信/支付宝小程序、支付宝嵌入页等网站及其他客户端（以下简称“平台”）。
      </p>
      <p>
        1.2平台提供方：指{{isXyk ?'海南麒麟云服科技有限公司' :'杭州中僖创智信息技术有限公司'}}及其关联公司。
      </p>
      <p>
        1.3平台规则：所有在{{isXyk}}服务平台内已发布的及后续发布的全部规则、公告、解释等内容。
      </p>
      <p>
        第二条 账户注册及使用要求
      </p>
      <p>
        2.1您开始注册使用平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果；
      </p>
      <p>
        2.2 {{isXyk}}平台只允许每位用户使用一个{{isXyk}}平台帐户，如果您存在不当注册或注册多个帐户或不当使用注册帐户，我们可采取冻结或关闭帐户、拒绝提供服务等措施，如果给平台或相关方造成损失的，您还应该承担赔偿责任。
      </p>
      <p>
        2.3由于您的平台帐户关联您的个人信息及平台商业信息，您的平台账户仅限您本人使用，未经平台同意，您直接或间接授权第三方使用您的平台账户或获取您账户项下信息的行为无效。您应妥善保管注册的用户名称、密码等信息，所有使用该信息的行为均视为您亲自作出。
      </p>
      <p>
        2.4.我们在任何时候均不会主动要求您提供您的注册账户、密码。因您保管、设置、主动泄露、使用不当或因您的注册帐户遭受他人攻击、诈骗等行为导致的损失及后果由您自行承担，我们对认证通过后的账号和密码的任何操作不承担任何责任，您应通过司法、行政等救济途径向侵权行为人追偿。
      </p>
      <p>
        2.5您应妥善保管银行、红包的账户、密码、数字证书以及绑定的手机号码、手机验证码等与账户有关的一切信息。您应确保不向其他任何人泄露账户密码、手机验证码。对于因账户密码或手机验证码泄露所致的损失，由您自行承担。如您发现有他人冒用或盗用您的平台帐号、银行帐户、密码或任何其他未经合法授权之情形时，应立即以有效方式通知平台。同时，您应理解平台对您的请求采取行动需要一定的合理期限，在此之前，平台对已执行的指令所导致的您的损失不承担任何责任。
      </p>
      <p>
        2.6仅当有法律明文规定、司法裁定或经平台同意，您可进行账户的转让，一但您的帐户进行转让，则所有权利义务均一并转移。如您私自转让您的帐户，则平台有权追究您的责任。
      </p>
      <p>
        2.7您同意，我们在需要进行证据保全的情况下，有权直接使用您的注册信息、用户名、密码等信息登录进入您的注册账户。
      </p>
      <p>
        2.8您应当及时更新您的基本资料，如因您资料更新不及时导致我们无法提供服务或提供了与您预期不一致的服务，由此产生的任何损失由您自行承担。
      </p>
      <p>
        2.9您理解并同意，如您有下列行为，我们有权暂停或终止向您提供全部或部分服务，由此导致的任何直接或间接损失由您自行承担：
      </p>
      <p>
        2.9.1您不具备履行本协议所需的民事行为能力；
      </p>
      <p>
        2.9.2未向我们提供真实、准确、完整、合法有效的身份信息，或上述信息存在争议；
      </p>
      <p>
        2.9.3未按要求进行实名注册；
  
      </p>
      <p>
        2.9.4 恶意注册，对平台/招聘单位/合作服务方进行恶意评价或攻击；
  
      </p>
      <p>
        2.9.5 我们认为您在交易过程中违反诚实信用原则，二次以上违约并遭受服务相对方投诉的，已导致您在平台上出现信用危机。
      </p>
      <p>
        第三条 信息保护
  
      </p>
      <p>
        3.1平台对您的信息承担保密义务，不会为满足第三方的营销目的而向其出售或出租您的任何信息，但基于法律法规规定、司法机关、监管机关、银行、第三方支付等合理要求下，我们披露您的个人信息。
  
      </p>
      <p>
        3.2您在此确认并同意，您授权我们就您的个人信息（包括但不限于：企业名称/姓名、性别、统一社会信用代码/身份证号码、电话号码、银行卡信息等）披露给相关的合作方，用于您在本平台或合作平台的与本协议相关的业务。
      </p>
      <p>
        3.3您同意，为向您提供更好的服务，我们将通过电子邮件、平台公告、手机短信、传真等方式向您发送相关活动通知。
      </p>
      <p>
        第四条 支付服务
      </p>
      <p>我们可能会通过第三方支付机构为您提供银行卡认证服务，在此服务下，您同意委托该第三方支付机构为您提供包括但不限于银行卡认证、提现、代扣、代发等服务，我们会将您的账户与前述银行账户进行绑定。如您未按照我们规定提交相关信息或提交的信息错误、虚假、过时或不完整，或者我们有合理的理由怀疑您提交的信息为错误、虚假、过时或不完整，您因此未能使用银行卡认证、提现、代发、代扣等服务而产生的损失由您自行承担。
      </p>
      <p>
        第五条 纳税义务
      </p>
      <p>
        作为自由职业者，您应该知晓，根据相关税收法律法规的要求，对于您提供的服务而获得的收入，我们或合作服务方将为您提供纳税代扣代缴服务。
      </p>
      <p>
        第六条 您的权利与义务
      </p>
      <p>
        6.1您在平台上报名参与合作服务方活动时，应在线下或线上与合作服务方确认工作时间与工作内容，您需要自行对您提供的自由职业者活动进行确认，如对平台合作服务方的活动有任何异议，您可自行向其确认，也可联系我们进行确认。
      </p>
      <p>
        6.2您在提供自由职业者活动时，应自觉遵守合作服务方的相关工作规定，服从其安排和管理，尤其是关于劳动保护、人身安全等涉及自身安全的事项。
      </p>
      <p>
        6.3您在提供自由职业者活动时，如违反合作服务方的操作规程或因个人原因给其或第三方造成损害的，您应承担全部赔偿责任。
      </p>
      <p>
        6.4您提供自由职业者活动后，合作服务方会向您支付相应的报酬，您应知晓，我们禁止任何平台入驻方或合作方以任何名义向您收取任何费用或克扣报酬，同时禁止任何合作服务方以任何非法手段强迫您从事非事先约定的服务内容，如果您发现有上述情况，请立即向我们投诉，我们将尽全力保障您的利益，如涉及到非法事项，您可立即向110报警处理。
      </p>
      <p>
        6.5您在提供自由职业者活动时，请您务必留存工作时间、工作内容的相关证据，以确保纠纷发生时保障您的合法权益。
      </p>
  
      <p>第七条 帐户管制 </p>
      <p>当出现以下情况之一时，我们有权对您的账户采取冻结、资金划扣、禁止提现等措施：</p>
      <p>7.1司法机关、行政机关、监管机构、银行、第三方支付机构或法律法规规定的其他有权机构要求；</p>
      <p>7.2我们有合理理由判断您使用他人的信息注册的；</p>
      <p>7.3您的账户出现包括但不限于注册异常、使用异常、登录异常、提现异常等异常情况时；</p>
      <p>7.4由于系统故障导致无法对您的账户资金流转情况进行记录和对账的；</p>
      <p>7.5我们有合理理由判断您的账户存在风险的（包括但不限于异常登录、异常操作、违反相关活动规则等情形）；</p>
      <p>7.6其他经我们判断采取上述措施有利于保护您的合法权益的情况。</p>
  
      <p>第八条 平台规则</p>
      <p>8.1您有义务遵守法律法规、政策以及我们制定的各项规则，不得非法使用我们提供的服务。我们有权基于单方独立判断，在认为可能发生危害交易安全等情形时，不经事先通知而先行暂停、中断或终止向您提供本协议项下的全部或部分服务，且无需对您承担任何责任。</p>
      <p>8.2前述情形包括但不限于：</p>
      <p>8.2.1我们认为您提供的个人资料不具有真实性、有效性或完整性；</p>
      <p>8.2.2我们发现账户异常或交易异常或有疑义时；</p>
      <p>8.2.3我们认为您的账户涉嫌洗钱、套现、传销、被冒用或其他我们认为有风险的情形。</p>
  
      <p>第九条 禁止行为 </p>
      <p>您同意并承诺将严格遵守以下义务：</p>
      <p>9.1不得利用我们从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动。</p>
      <p>9.2不干扰我们平台的正常运转，不得侵入平台计算机信息系统。</p>
      <p>若您未遵守以上规定，我们有权作出独立判断并采取暂停或关闭账户措施。</p>
  
      <p>第十条 免责声明</p>
      <p>10.1任何非我们直接提供的第三方服务，其服务内容及质量由该第三方自行、独立负责。</p>
      <p>10.2因不可抗力或服务器死机、常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等数据库故障、软件升级或发生自然灾害、战争、恐怖袭击或者其他无法控制的事件等问题造成的服务中断和对用户个人数据及资料造成的损失，我们对此不承担任何责任。</p>
      <p>10.3因黑客、病毒或密码被盗、泄露等非我们原因造成损失的，我们对此不承担任何责任。</p>
      <p>10.4当司法机关、政府部门或其他监管机构根据有关法律、法规、规章及其他政府规范性文件的规定和程序，要求我们提供您的信息资料，我们对据此作出的任何披露，均为正当行为。</p>
      <p>10.5由于中国法律政策的变动或监管机关的要求导致我们开展的业务不被中国法律政策、监管机关允许或认可，由此造成的损失由您自行承担，我们对此不承担任何责任。</p>
      <p>10.6我们可能会提供与其它互联网网站或资源的链接。由于我们无法控制这些网站及资源，您在此了解并同意，此类网站或资源的任何内容、广告、产品或其它资料，我们不予保证或负责。因使用或依赖此类网站的商品或服务产生的任何损失，我们对此不承担任何责任。</p>
      <p>
        第十一条 协议变更</p>
      <p>平台会根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”），平台将通过公告形式通知您。如您不同意变更事项，您有权于变更事项确定的生效日前联系平台反馈意见，如反馈意见得以采纳，平台将酌情调整变更事项。如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用平台服务，变更事项对您不产生效力，如您在变更事项生效后仍继续使用平台服务的，则视为您同意已生效的变更事项。</p>
  
      <p>第十二条 协议终止</p>
      <p>12.1符合下列条件之一的，本协议自然终止：</p>
      <p>12.1.1如果您明示不愿意继续使用平台服务而注销平台帐户且符合平台规定的；</p>
      <p>12.1.2如您盗用他人账户、发布违禁信息、骗取他人财物、扰乱市场秩序、采取不正当手段谋利等行为，平台依法依约对您的账户予以查封的；</p>
      <p>12.1.3您违反平台其他规则被平台强制注销帐号的，协议自然终止。</p>
      <p>12.2 协议终止后，除法律有明确规定外，平台不会向您或您指定的第三方披露您账户中的任何信息。</p>
      <p>12.3协议终止后，平台仍享有下列权利：</p>
      <p>12.3.1继续保留您在平时注册、使用过程中留存的所有信息；</p>
      <p>12.3.2对于您使用平台服务期间而发生的违约行为，平台或权利方仍可向您追究违约责任；</p>
      <p>12.3.3对于您在平台注册使用期间尚未提供完的服务或尚未履行支付义务的，您仍有义务按约定履行完毕。</p>
  
      <p>第十三条 签约授权</p>
      <p>13.1您在“{{isXyk}}”平台注册期间，可能涉及与第三方公司签订《自由职业者服务协议》，您作为该协议中的自由职业者身份，需同意授权平台提供方代您与第三方公司签约（包括主协议、补充协议、授权委托书等）。</p>
      <p>13.2平台提供方有权以您的名义在“{{isXyk}}“平台代您签约《自由职业者服务协议》（模板见附件一），由此产生的所有法律后果由您承担。</p>
      <p>13.3授权期限为您在“{{isXyk}}”服务平台注册之日起至帐户注销或被撤销之日止，或者您也可以在“{{isXyk}}”服务平台上个人账号内操作，对已签约（包括委托签约的）的单个第三方供应商公司提交解除合作申请。</p>
  
      <p>第十三条 争议解决</p>
      <p>因本协议的签订及履行所引起的任何纠纷或争议，首先应友好协商解决，协商不成的，各方同意提交杭州互联网法院诉讼解决。</p>
      <p>第十四条 法律文书送达地址确认</p>
      <p>14.1您在此确认，司法机关（包括但不限于人民法院）可以手机短信或电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括诉讼文书）</p>
      <p>14.2您确认您同意邮寄地址为您身份证所示户口所在地，您在此确认本地址真实、有效、若本地址发生变更的，您应该及时通知平台。若您提供的送达地址不真实、不准确，或未及时向平台或司法机关告知变更后的地址，导致平台或司法机关向您发送的法律文书未被实际接收的，平台或司法机关以前述任一送达地址发出法律文书均视为送达，您应该承担由此产生的相应法律后果。</p>
  
      <p>第十五条 其它 </p>
      <p>本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其它条款的有效性及可执行性。</p>
  
  
  
  
  
  
  
  
  
  
  
      <p>附件一：《自由职业者服务协议》</p>
      <p> 自由职业者服务协议</p>
  
      <p>提示条款：</p>
      <p>欢迎您与我司【 】签署本《自由职业者服务协议》（下称“本协议”）并为我司合作伙伴【 】公司（以下简称为“合作公司”）提供服务。</p>
      <p>【审慎阅读】为维护您的自身权益，在您点击同意或书面签署本协议之前，请认真阅读本协议，务必审慎阅读、充分理解各条款内容，特别是权利义务条款、法律适用和管辖条款。上述条款以粗体及下划线标识，您应重点阅读。</p>
      <p>【签约】当您按照提示填写您的个人信息、阅读并同意本协议且完成全部申请或书面签署后，即表示您已充分阅读、理解并接受本协议的全部内容，并与我司达成一致意见并成为我司的合作人员，此后您不得以未阅读/不理解本协议内容或类似言辞做任何形式的抗辩。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，请您立即停止任何的申请/注册/或书面签署程序。</p>
      <p>本协议项下为我司或/及其用户/合作公司提供服务的仅限于获得生产经营收入的自由职业者。</p>
      <p>军人、公职人员等国家法律法规和纪律规定禁止从事兼职或经商的人员，严禁使用本协议项下我司提供的综合服务。</p>
      <p>合作公司雇员等其他与合作公司具有劳动/劳务合同关系或其他类似的劳动人事法律关系并从与其有前述关系的公司取得工资薪金所得或劳务报酬所得的人员，严禁使用本协议项下我司提供的综合服务。</p>
      <p>合作公司法定代表人、股东、董事、监事等其他从所属公司（含关联方）取得收入的人员，严禁使用本协议项下我司提供的综合服务。</p>
      <p>一经发现违反上述任一规定行为的，我司有权上报我司主管税务机关或/及其他相关国家机关，由我司主管税务机关或/及其他相关国家机关根据《中华人民共和国税收征收管理法》及其他相关法律法规的规定追究责任主体的法律责任。</p>
      <p>【合作关系】您与我司通过本协议建立合作关系，适用《合同法》《民法总则》和其他民事法律，不适用《劳动合同法》。</p>
      <p>【定义】</p>
      <p>1、关联方：指相互间直接或间接持有其中一方的股权总和达到25%或以上的；或者直接或间接同为第三者所拥有或控制股权达到25%或以上的；或者对自身生产经营、交易具有实际控制的与其他利益相关联的关系（包括但不限于婚姻、近亲属关系；股权代持关系；对董事会拥有控制权等）。</p>
      <p>2、自由职业者：指向合作公司提供合法合规生产经营的自然人个人。</p>
      <p>3、{{isXyk}}：第三方线上签约及经营性活动任务发布操作平台。</p>
      <p>协议条款：</p>
      <p>根据国家法律有关规定，双方按照平等自愿、诚实信用的原则，就由您为合作公司提供服务事宜订立本自由职业者服务协议。</p>
      <p>第一条 服务工作内容</p>
      <p>1.1 因我司与合作公司服务合作需要，特选用您按照我司与合作公司相关合同的规定提供【 】服务。</p>
      <p>1.2 您在签订本协议或者通过“{{isXyk}}”平台接受生产经营活动任务后，表示您已清楚为合作公司提供的生产经营活动内容，并同意接受我司的推荐向合作公司提供相应生产经营活动，并按照合作公司业务规则查询活动需求、接受活动需求、履行生产经营活动过程中的相关义务并享有相关权利等。</p>
      <p>1.3 您应按照我司与合作公司相关合同要求规定的标准完成工作。您承诺提供服务过程中遵守合作公司规定的活动条件、活动内容、活动注意事项等；我司依据合作公司提供的与您相关的服务的数量、质效标准及考核结果，核算最终对应到您的生产经营收入（税后）。</p>
      <p>1.4 您承诺和保证，您具有提供本协议服务所对应的相应资质和专业能力，您依据本合同约定收取相关费用不违反任何法律法规和政策规定。若依据相关规定，您提供的相关服务和收费，应通过所在机构或以其他法律规定的方式进行，则您自行负责办理全部事宜，若因您的自身原因未办理前述事宜，则您应承担由此引起的全部法律后果，并赔偿由此给我司及合作公司造成的全部经济损失。</p>
  
      <p>协议效力</p>
      <p>2.1 您的签约信息（包括您的姓名、身份证号等信息），以您提供的信息为准（包括但不限于线上提供或线下提供）。您承诺并保证您所提供的签约信息真实、准确，以免影响您和我司之间的《自由职业者服务协议》效力。本协议条款若在我司授权的网站上发布，自您线上点击确认之日起生效；若为书面签署，自您签署本协议之日起生效。</p>
      <p>2.2 本协议自签订之日起一年内有效。期限届满，双方应另行签署新的协议；若双方未签署新的协议，而以实际行动继续合作的，视为双方同意协议期限顺延直至按照本协议约定终止或解除时为止。</p>
      <p>2.3 本协议在发生下列情形时自然终止：</p>
      <p>a、 经合作公司事先同意的情况下，您主动退出服务的；</p>
      <p>b、 合作公司终止或变更业务模式而不需要您继续履行本协议的；</p>
      <p>c、 合作公司认为您不适合继续提供服务的；</p>
      <p>d、 我司和合作公司解除合作的；</p>
      <p>e、 其他原因导致本协议不再继续履行的。</p>
  
      <p>第三条 我司的权利和义务</p>
      <p>3.1 您应根据我司的安排为合作公司服务，服务期间您应遵守合作公司有关服务内容、服务质量等方面的要求。合作公司可根据自身的运营要求制定具体工作安排，您应予遵守。</p>
      <p>3.2 我司将按合作公司的相关规定与合作公司共同对您为合作公司提供服务的工作成果进行验收。</p>
      <p>3.3 我司应按约定及时向您支付费用。此处您应明确：您作为我司的合作人员，在合作公司正常向我司结算情况下，您的相关费用结算将由我司完成支付，您不应向合作公司或其关联公司主张任何费用。您通过合作公司渠道发起的提现/或任何申请支付的行为，均视为向我司发起，而并非向合作公司或其关联公司发起。</p>
      <p>3.4 我司在向您支付其生产经营收入（税后）时，有权在我司所在区向我司主管税务机关代您进行税务登记或临时税务登记，并按照有关税收政策规定及我司主管税务机关的要求，向您代征个人所得税税款。</p>
      <p>3.5 您同意，您不可撤销地授权我司和合作公司有权为提供前述费用结算服务的需要获取您的相关信息（包括但不限于个人身份信息、实名登记手机号码、银行账户相关信息等），并将您的相关信息提交第三方机构进行身份验证。若您提供任何错误、虚假、失效或不完整的资料，或者我司或合作公司有合理的理由怀疑资料为错误、虚假、过期或不完整，我司或合作公司有权暂停或终止向您提供部分或全部服务，对此我司或合作公司不承担任何责任，您承诺并同意负担因此所产生的所有损失，包括但不限于直接损失、间接损失。若因国家法律法规、部门规章或监管机构的要求，我司或合作公司需要您补充提供任何相关资料时，如您不能及时配合提供，我司或合作公司有权暂停或终止向您提供部分或全部服务。</p>
      <p>3.6 我司（含股东会、董事会、监事会成员及高级管理人员）不得将您个人信息披露给其他无关联的任意第三方，如我司违反此约定造成您相应经济损失的，我司应承担该损失的全部赔偿责任。我司承诺按照相关法律法规的要求对您个人信息履行安全保护义务，保障网络免受干扰、破坏或未经授权的访问，防止网络数据泄露或被窃取、篡改。</p>
      <p>3.7 如合作公司与您之间另有服务或其他约定安排的，我司不就我司未参与的安排承担任何义务。</p>
      <p>3.8 我司应当本着对您利益最大化的原则，勤勉履行本协议，不得损害您的合法权益。</p>
  
      <p>第四条 您的权利和义务</p>
      <p>4.1 您保证签署本协议时系具有完全民事权利能力和民事行为能力的民事主体，并满足所有履行本协议所需的法定条件或约定条件。</p>
      <p>4.2 您有权在我司处查询生产经营收入（税后）的计算方式、收取生产经营收入（税后）。您保证为合作公司提供的生产经营活动是以您个人名义进行的，与您名下的个体工商户（如有）没有任何关系。</p>
      <p>4.3 您应按时尽责地完成我司以及合作公司要求的工作内容，并达到规定的质量标准，不得有侵犯他人知识产权、财产权、人身权、肖像权、隐私权、商业秘密或有悖于公序良俗的内容。您保证活动过程中遵守国家法律法规，不得从事违反法律及行政法规等行为（包括但不限于涉嫌洗钱、偷税、漏税、逃税）及其他不得使用我司服务的行为（包括但不限于您为合作公司提供活动的特点、性质不符合本协议第一条约定的您服务工作内容范围的情形），我司/合作公司主管税务机关或/及其他相关国家机关介入调查时，您应当予以协助并如实反映情况，不得阻挠、拒绝检查。</p>
      <p>4.4 协议期内，您应在我司以及合作公司许可范围内使用本合同有关的商业信息；协议期内以及协议终止后，您均有义务对双方合作的有关协议及合作过程中知悉的我司、合作公司的相关信息予以保密，未经书面许可，您不得以任何方式向其它方披露、给予或转让该等保密信息。否则，因此导致我司及/或合作公司或其关联公司的损失，由您负责赔偿。</p>
      <p>4.5 除本协议事项外，您不能以我司及/或合作公司的名义开展任何与完成约定的工作任务无关的业务或活动。您充分理解自由职业者的定义，并承诺不存在下列所述情形：</p>
      <p>4.5.1与合作公司（含关联方）具有劳动/劳务合同关系或其他类似的劳动人事法律关系；</p>
      <p>4.5.2为合作公司（含关联方）的法定代表人、股东、董事、监事；</p>
      <p>4.5.3军人、公职人员等国家法律法规和纪律规定禁止从事兼职或经商的人员；</p>
      <p>4.5.4其他不适用于本协议第一条我司服务范围之规定的人员。</p>
      <p>4.6 若相关服务通过合作公司指定的网络平台提供，或者合作公司指定的相关网络平台备案，合作公司指定的网络平台可能将要求您签署、点击电子协议，您同意点击签署。您点击确认后该协议将对您产生法律效力。</p>
      <p>4.7 您于我司或“{{isXyk}}”平台处注册时所使用的唯一身份标识仅限本人使用，因您泄露或提供给他人使用该等身份标识而造成的一切后果，由您自行承担，与我司无关。</p>
      <p>4.8 您应当本着我司利益最大化的原则，勤勉履行本协议，维护我司形象，不得损害我司的合法权益。</p>
  
  
      <p>第五条 服务费用的支付</p>
      <p>5.1我司将根据经合作公司确认的您为合作公司所提供服务的数量、质效标准及考核结果核算向您支付服务费用。由于您所提供的服务内容、服务质量、服务时长等情况的不同，该等服务费用金额可能会呈现浮动，您清楚并了解该等浮动为正常情况。我司以人民币形式向您支付服务费用，您应缴纳的个人所得税及其他税费由我司负责代扣代缴。</p>
      <p>5.2 您应向我司提供您实名下的银行账户或支付宝账户，以及相关必要的证件和信息。若具体账户信息在合作公司指定的网络平台在线提供的，以您在线提供的信息为准。。</p>
      <p>5.3 我司服务费用支付以您提供的收款帐户为准，因您提供的收款帐户不实造成的一切损失由您自行承担。 如您帐号变更或发生不可用等情况时，应及时书面通知我司或在合作公司指定的网络平台进行变更操作，否则，由此造成的一切损失由您自行负责。</p>
      <p>5.4 我司不承担您任何社保福利待遇，也不负责您的任何医疗保险费用。</p>
      <p>5.5 服务费计算标准见本协议最下方，服务费结算周期不作明确约定，视具体活动内容及需求，双方可自行协商。</p>
  
  
      <p>第六条 违约责任</p>
      <p>6.1 双方应按本协议约定履行，如有违反，守约方有权要求对方及时改正；造成对方损失的，守约方有权要求违约方赔偿。</p>
      <p>6.2 因不可抗力造成损失的，彼此不负赔偿责任，但发生不可抗力一方应及时将有关情况通知另一方，并尽最大努力进行补救。本协议所称不可抗力是指不能预见、不能克服并不能避免且对一方当事人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、火灾和风暴等以及社会事件如战争、动乱、政府行为、黑客事件、大范围电信事故等。</p>
      <p>6.3 因政府行为（包括但不限于因中国政府机关不授予或调整甲方相应经营资质或权利等）或其他不可抗力因素导致本协议部分条款或全部条款无法履行的，遭受该行为的一方不承担违约责任。</p>
  
  
      <p>第七条 协议的终止</p>
      <p>7.1 发生以下情形时，本协议终止：</p>
      <p>协议期限届满或任何一方提前30日书面通知对方要求终止协议的，但您要求终止协议应事先获得合作公司的同意并不得影响合作公司的运营；</p>
      <p>一方违反协议约定，且违约方在非违约方书面通知后15日内仍未予改正的，非违约方可以书面通知的形式终止本协议；</p>
      <p>您如未能达到我司的要求，我司在工作验收时将给予警告；此后如仍发生类似情况的，我司可提前终止本协议。</p>
      <p>7.2 本协议的提前终止不影响已经产生的权利义务关系。</p>
  
  
      <p>涉税事务委托授权</p>
      <p>您/本人委托我司代理本合同交易项下的涉税事务，包括代理报税、代开发票和代开个人完税证明。</p>
      <p>您/本人确认我司根据本合同授权提交的相关申报资料和信息是真实、完整、准确，和符合有关法律法规的。</p>
      <p>您/本人确认与我司的合作公司不存在法律上和事实上的人事劳动关系，不属于合作公司人事管理权限范围内的个人，也并非其股东。</p>
  
  
      <p>其他</p>
      <p>9.1 因履行本协议发生的纠纷，双方应友好协商解决，协商不成的，提请我司所在地人民法院诉讼解决。</p>
      <p>9.2 我司有权根据业务需要修改本协议条款。</p>
      <p>（以下无正文）</p>
  
  
      <p>甲方（我司）：【 <span class="blank">     &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span>  】</p>
  
  
      <p>签章：【 <span class="blank">     &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span>  】</p>
      <p>乙方（您）：【 <span class="blank">     &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span>  】</p>
  
  
      <p>签字：【 <span class="blank">     &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span>  】身份证号：【  <span class="blank">     &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span> 】</p>
      <p>签署日期：【 <span class="blank">     &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span>  】</p>
  
  
      <p>服务费计算标准</p>
      <p>您与我司签署本《自由职业者服务协议》并为我司合作伙伴提供服务。服务费计算标准按照下列规则执行：</p>
  
      <table class="tb-pay">
        <thead>
          <tr>
            <td class="col-1">项目类型</td>
            <td class="col-2">工作内容及计算标准</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> 1. <br> 【
              <span class="blank">     &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span> 】</td>
            <td>
              【<span class="blank">&nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span> 】</td>
          </tr>
          <tr>
            <td>2. <br> 【
              <span class="blank">     &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span> 】</td>
            <td>
              【<span class="blank">&nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span> 】</td>
          </tr>
          <tr>
            <td>3. <br> 【
              <span class="blank">     &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span> 】</td>
            <td>
              【<span class="blank">&nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span> 】</td>
          </tr>
          <tr>
            <td>4. <br> 【
              <span class="blank">     &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span> 】</td>
            <td>
              【<span class="blank">&nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span> 】</td>
          </tr>
          <tr>
            <td>5. <br> 【
              <span class="blank">    &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span> 】</td>
            <td>
              【<span class="blank">&nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   &nbsp;   </span> 】</td>
          </tr>
          <tr>
            <td>浮动绩效</td>
            <td>额外临时性活动或优秀服务奖励绩效以实际情况约定为准。</td>
          </tr>
        </tbody>
      </table>
  
  
  
    </div> 
  </div>
</template>
<script>
export default {
  data(){
    return {
      isXyk:window.location.origin.indexOf('h5.kylincloud-saas') > -1 ?'麒麟云服' :'僖游客'
    }
  }
}
</script>
<style lang="less" scoped>
  .content {
    padding: 20px;
    font-size: 1rem;
    line-height: 1.8rem;
    p {
      text-indent: 2rem;
      margin-bottom: 1rem;
    }
    .blank {
      text-decoration: underline;
      display: inline-block;
    }
    .tb-pay {
      width: 100%;
      background: #ddd;
      td {
        // border:1px solid #ddd;
        background-color: #fff;
        padding: 5px;
      }
      .col-1 {
        width: 6rem;
        .blank {
          width: 4rem;
        }
      }
      .col-2 {
        .blank {
          width: 20rem;
        }
      }
    }
  }
</style>
